import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import dark from "../assets/images/dark.svg";
import light from "../assets/images/light.svg";
import darklogo from "../assets/images/darklogo.png";
import "./Header.css";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [theme, setTheme] = useState("light-theme");
  const [activeItem, setActiveItem] = useState("home");
  const [scroll, setScroll] = useState(false);

  // scroll class
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  // add toggler class
  const ref = React.useRef(null);
  const reff = React.useRef(null);
  const tollgerClass = (e) => {
    ref.current.classList.toggle("show-menu");
    reff.current.classList.toggle("show-menu");
  };

  // remove toggler class
  const onRemoveClass = (e) => {
    reff.current.classList.remove("show-menu");
  };

  // active class
  const handleClick = (item) => {
    setActiveItem(item);
  };

  // dark - light theme
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
  };
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <header className={scroll ? "bg-black" : "bg-white"}>
        <nav
          className="relative flex flex-wrap items-center justify-between px-2 py-3"
          ref={reff}
        >
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                className="leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                href="/"
              >
                <img className="logo" src={logo} alt="logo" />
                <img src={darklogo} className="dark" alt="img" />
              </Link>
              <div className="mode">
                <div className="theme">
                  <input type="checkbox" class="checkbox" id="chk" />
                  <label class="label" for="chk" onClick={() => toggleTheme()}>
                    <div class="ball">
                      <span className="dark">
                        <img src={light} alt="" />
                      </span>
                      <span className="light">
                        <img src={dark} alt="" />
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <button
                onClick={tollgerClass}
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon">
                  <div
                    class="plate plate4"
                    onclick="this.classList.toggle('active')"
                  >
                    <svg
                      class="burger"
                      version="1.1"
                      height="100"
                      width="100"
                      viewBox="0 0 100 100"
                    >
                      <path class="line line1" d="M 50,35 H 30" />
                      <path class="line line2" d="M 50,35 H 70" />
                      <path class="line line3" d="M 50,50 H 30" />
                      <path class="line line4" d="M 50,50 H 70" />
                      <path class="line line5" d="M 50,65 H 30" />
                      <path class="line line6" d="M 50,65 H 70" />
                    </svg>
                    <svg
                      class="x"
                      version="1.1"
                      height="100"
                      width="100"
                      viewBox="0 0 100 100"
                    >
                      <path class="line" d="M 34,32 L 66,68" />
                      <path class="line" d="M 66,32 L 34,68" />
                    </svg>
                  </div>
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul
                className="navbar-nav flex flex-col lg:flex-row list-none lg:ml-auto"
                ref={ref}
              >
                <li onClick={onRemoveClass}>
                  <HashLink
                    to="/#home"
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "home" ? "active" : ""
                    }`}
                    onClick={() => handleClick("home")}
                  >
                    <span>H</span>
                    <span>o</span>
                    <span>m</span>
                    <span>e</span>
                  </HashLink>
                </li>
                <li onClick={onRemoveClass}>
                  <HashLink
                    to="/#aboutus"
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "about" ? "active" : ""
                    }`}
                    onClick={() => handleClick("about")}
                  >
                    <span>A</span>
                    <span>b</span>
                    <span>o</span>
                    <span>u</span>
                    <span>t</span>
                  </HashLink>
                </li>
                <li className="nav-item" onClick={onRemoveClass}>
                  <Link
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "portfolio" ? "active" : ""
                    }`}
                    onClick={() => handleClick("portfolio")}
                    to="/portfolio"
                  >
                    <span>P</span>
                    <span>o</span>
                    <span>r</span>
                    <span>t</span>
                    <span>f</span>
                    <span>o</span>
                    <span>l</span>
                    <span>i</span>
                    <span>o</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={onRemoveClass}>
                  <HashLink
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "services" ? "active" : ""
                    }`}
                    onClick={() => handleClick("services")}
                    to="/#services"
                  >
                    <span>S</span>
                    <span>e</span>
                    <span>r</span>
                    <span>v</span>
                    <span>i</span>
                    <span>c</span>
                    <span>e</span>
                    <span>s</span>
                  </HashLink>
                </li>
                <li className="nav-item" onClick={onRemoveClass}>
                  <Link
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "blog" ? "active" : ""
                    }`}
                    onClick={() => handleClick("blog")}
                    to="/blog"
                  >
                    <span>B</span>
                    <span>l</span>
                    <span>o</span>
                    <span>g</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={onRemoveClass}>
                  <HashLink
                    className={`flex items-center text-xs leading-snug text-white hover:opacity-75 home ${
                      activeItem === "contact" ? "active" : ""
                    }`}
                    onClick={() => handleClick("contact")}
                    to="/#contact"
                  >
                    <span>C</span>
                    <span>o</span>
                    <span>n</span>
                    <span>t</span>
                    <span>a</span>
                    <span>c</span>
                    <span>t</span>
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
