import React from "react";
import { Link } from "react-router-dom";
import works1 from "../../../assets/images/works1.png";
import "./Amazing.css";
import api from "../../../Punitportfolio";
import Fade from "react-reveal/Fade";
import Fancybox from "../../../componate/fancybox/FancyBox";

const Amazing = () => {
  return (
    <>
      <section className="amazingwords">
        <div className="container mx-auto">
          <div className="myamazingwords">
            <Fade bottom cascade>
              <h2 className="whatcanidothat main_title_head">
                My amazing works
              </h2>
              {/* <p className='time_para'>Sit orci imperdiet adipiscing pretium, duis nisi. Lectus etiam hac tellus accumsan phasellus vestibulum.</p> */}
            </Fade>
          </div>
          <div className="grid grid-cols-2 gap-7">
            <Fade bottom big>
              <div className="appredesign">
                <Fancybox>
                  <img data-fancybox="gallery" src={works1} alt="works1" />
                </Fancybox>
              </div>
            </Fade>

            <div className="luca_vector_calender">
              <div className="grid grid-cols-2 gap-7">
                {api.amazing.map((data, index) => {
                  return (
                    <Fade right big cascade key={index.id}>
                      <div div className="luca_web_main">
                        <div className={data.className}>
                          <img
                            data-fancybox="gallery"
                            src={data.image}
                            alt="img"
                          />
                        </div>
                      </div>
                    </Fade>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="viewall_btn">
            <Link to="/portfolio" className="viewallbtn">
              View all
              <span className="next">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9225 7.41078L12.5 0.988281L11.3217 2.16661L16.3217 7.16661H1.66671C1.20587 7.16661 0.833374 7.53995 0.833374 7.99995C0.833374 8.45995 1.20587 8.83328 1.66671 8.83328H16.3217L11.3217 13.8333L12.5 15.0116L18.9225 8.58911C19.2484 8.26328 19.2484 7.73661 18.9225 7.41078Z"
                    fill="white"
                  />
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Amazing;
