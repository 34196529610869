import React from "react";
import "./Services.css";
import api from "../../../Punitportfolio";

const Services = () => {
  return (
    <>
      <section className="servicesioffer" id="services">
        <div class="container mx-auto">
          <div className="servicesioffermain">
            <div className="ultriciesmauris">
              <div data-aos="fade-up" data-aos-duration="1000">
                <span className="ultriciesmauris_mauris text-[#217BF4] description_main">
                  Ultricies mauris tincidunt
                </span>
                <h2 className="igiveaoffer main_title_head">
                  Services I can offer
                </h2>
                {/* <p className='services_feugiat time_para'>Feugiat facilisi ac ac purus nibh mattis auctor turpis at. Lorem luctus mattis tincidunt eget accumsan.</p> */}
              </div>
            </div>
            <div className="frontend">
              <ul className="application">
                {api.services.map((data) => {
                  return (
                    <>
                      <li
                        data-aos="zoom-in-up"
                        className="iofferdeveloperservices"
                        key={data.id}
                      >
                        <div className="iofferdeveloperservicesdata">
                          <div className="frontdeveloperinnerimg">
                            <img src={data.images} alt="img" />
                          </div>
                          <div className="application">
                            <h5 className="card_title_main">{data.title}</h5>
                            <p className="card_description_main">
                              {" "}
                              {data.description}{" "}
                            </p>
                            <div className="tagedata_span">
                              {" "}
                              {data.tags.map((paragraph) => (
                                <span>{paragraph}</span>
                              ))}{" "}
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
