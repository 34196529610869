import React from "react";
import "./Experience.css";
import api from "../../../Punitportfolio";
import Fade from "react-reveal/Fade";

const Experience = () => {
  return (
    <>
      <section className="dofor_clients">
        <div className="container mx-auto">
          <div className="work_experience">
            <div className="what_dofor_clients">
              <Fade bottom>
                <span className="work_ex description_main">
                  past work experience
                </span>
                <h2 className="igiveaoffer main_title_head">
                  My Work Experience
                </h2>
                <p className="services_feugiat time_para">
                  Specialising in the latest web design offering web consultancy
                  and digital solutions to increase leads and sales.
                </p>
              </Fade>
            </div>
            <ul className="webdeveloper_tabledata" data-aos="fade-up">
              {api.experience.map((data) => {
                return (
                  <>
                    <li className="tabledatainner" key={data.id}>
                      <div className={data.className}>
                        <div className="delemont">
                          <div className="delemontimg">
                            <img src={data.delemontlogo} alt="img" />
                          </div>
                          <div className="delemont_location">
                            <p className="febgruh"> {data.company} </p>
                            <p className="country_location">{data.location} </p>
                          </div>
                        </div>
                        <div className="filds_name">
                          {" "}
                          <span> {data.fildname} </span>
                        </div>
                        <div className="tableyear">
                          {" "}
                          <p> {data.year} </p>
                        </div>
                        <div className="gotoweb">
                          <a
                            href={data.link}
                            target={"_punitbhai"}
                            className="goto_next_website"
                          >
                            <span className="gonextpage"> {data.gonext} </span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.4301 18.82C14.6201 18.82 14.8101 18.75 14.9601 18.6L21.0301 12.53C21.3201 12.24 21.3201 11.76 21.0301 11.47L14.9601 5.4C14.6701 5.11 14.1901 5.11 13.9001 5.4C13.6101 5.69 13.6101 6.17 13.9001 6.46L19.4401 12L13.9001 17.54C13.6101 17.83 13.6101 18.31 13.9001 18.6C14.0401 18.75 14.2401 18.82 14.4301 18.82Z"
                                fill="url(#paint0_linear_35_347)"
                              />
                              <path
                                d="M3.50008 12.75H20.3301C20.7401 12.75 21.0801 12.41 21.0801 12C21.0801 11.59 20.7401 11.25 20.3301 11.25H3.50008C3.09008 11.25 2.75008 11.59 2.75008 12C2.75008 12.41 3.09008 12.75 3.50008 12.75Z"
                                fill="url(#paint1_linear_35_347)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_35_347"
                                  x1="21.2476"
                                  y1="7.88158"
                                  x2="10.6242"
                                  y2="12.8538"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#65E7A7" />
                                  <stop offset="1" stop-color="#2CCFC5" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_35_347"
                                  x1="21.0801"
                                  y1="11.5469"
                                  x2="20.7877"
                                  y2="14.5619"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#65E7A7" />
                                  <stop offset="1" stop-color="#2CCFC5" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
