import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "./Footer.css";
import darklogo from "../assets/images/darklogo.png";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container mx-auto">
          <div className="thatsme">
            <div className="grid grid-cols-4 gap-5">
              <div className="footerlogo">
                <Link to="/">
                  <img className="lightlogo" src={logo} alt="img" />
                  <img className="darklogo" src={darklogo} alt="img" />
                </Link>
                <p className="card_description_main">
                  As a senior software developer with expertise in PHP, I have
                  extensive experience in designing, developing, and deploying
                  complex web-based applications.
                </p>
              </div>
              <div className="navigation navigation_menu">
                <p>Navigation</p>
                <ul className="aboutus">
                  <li>
                    <HashLink to="#aboutus">About Us</HashLink>
                  </li>
                  <li>
                    <HashLink to="#contact">Contact us</HashLink>
                  </li>
                  <li>
                    <Link to="/">Projects</Link>
                  </li>
                  {/* <li><Link to="/">Recent post</Link></li> */}
                </ul>
              </div>
              <div className="navigation navigation_menu">
                <p>All Services</p>
                <ul className="aboutus">
                  <li>
                    <Link to="/">Web Design</Link>
                  </li>
                  <li>
                    <Link to="/">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/">Brand Identity</Link>
                  </li>
                  <li>
                    <Link to="/">Digital Marketing</Link>
                  </li>
                </ul>
              </div>
              <div className="navigation email">
                <ul className="footericonslogo">
                  <li className="footer_social_icon">
                    <a
                      href="https://m.facebook.com/kathiriya.punit"
                      target="_punitk"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="footer_social_icon">
                    <a
                      href="https://telegram.me/kathiriyapunit"
                      target="_punitk"
                    >
                      <i class="fab fa-telegram-plane"></i>
                    </a>
                  </li>
                  <li className="footer_social_icon">
                    <a
                      href="https://skype:punitkathiriya?chat"
                      target="_punitk"
                    >
                      <i class="fab fa-skype"></i>
                    </a>
                  </li>
                  <li className="footer_social_icon">
                    <a
                      href="https://wa.me/+358415739627?text=Hello! Punit"
                      target="_punitk"
                    >
                      <i class="fab fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div></div>
            </div>
            <div className="copyright">
              <p>Copyright © 2023 That’s me. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
