import React, { useEffect } from "react";
import "./Clients.css";
import api from "../../../Punitportfolio";
import Aos from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const Clients = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  // const classis = {
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     450: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 1,
  //     },
  //     1031: {
  //       items: 1,
  //     },
  //     1200: {
  //       items: 1,
  //     },
  //     1920: {
  //       items: 1,
  //     },
  //   },
  // };

  return (
    <>
      <section className="client">
        <div className="container mx-auto">
          <div className="testimonialsdiv">
            <div className="client_review">
              <div data-aos="fade-up" data-aos-duration="1000">
                <span className="testimonials description_main">
                  Testimonials
                </span>
                <h2 className="igiveaoffer main_title_head">
                  What my clients say
                </h2>
                {/* <p className="services_feugiat time_para">Most common methods for designing websites that work well on desktop is responsive & adaptive design</p> */}
              </div>
            </div>
            <div className="clientreviewdetail">
              <Swiper
                navigation={true}
                loop={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {api.clients.map((data) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="item" key={data.id}>
                          <div className={data.className}>
                            <img src={data.image} alt="img" />
                            <h6> {data.name} </h6>
                            <span> {data.linkid} </span>
                            <ul className="star">
                              <li className="reviewstar">
                                <i class="fas fa-star"></i>
                              </li>
                              <li className="reviewstar">
                                <i class="fas fa-star"></i>
                              </li>
                              <li className="reviewstar">
                                <i class="fas fa-star"></i>
                              </li>
                              <li className="reviewstar">
                                <i class="fas fa-star"></i>
                              </li>
                              <li className="reviewstar">
                                <i class="fas fa-star"></i>
                              </li>
                            </ul>
                            <p> {data.description} </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;

// <OwlCarousel
//   className="owl-theme"
//   items={1}
//   loop
//   margin={30}
//   responsiveClass={true}
//   autoHeight={true}
//   nav={true}
//   dots={false}
//   navText={[
//     `
//                                         <span className="arrow prev">
//                                             <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                 <circle cx="10.5" cy="11.5" r="10.5" fill="#FFF3BF"/>
//                                                 <path d="M15.57 5.92993L9.5 11.9999L15.57 18.0699" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
//                                                 <path d="M26.4999 12H9.66992" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
//                                             </svg>
//                                         </span>`,
//     `<span classname="arrow next">
//                                             <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                 <circle r="10.5" transform="matrix(-1 0 0 1 19.5 11.5)" fill="#FFF3BF"/>
//                                                 <path d="M14.43 5.92993L20.5 11.9999L14.43 18.0699" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
//                                                 <path d="M3.50008 12H20.3301" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
//                                             </svg>
//                                         </span>
//                                     `,
//   ]}
//   smartSpeed={1000}
//   {...classis}
// >
//   {api.clients.map((data) => {
//     return (
//       <>
//         <div className="item" key={data.id}>
//           <div className={data.className}>
//             <img src={data.image} alt="img" />
//             <h6> {data.name} </h6>
//             <span> {data.linkid} </span>
//             <ul className="star">
//               <li className="reviewstar">
//                 <i class="fas fa-star"></i>
//               </li>
//               <li className="reviewstar">
//                 <i class="fas fa-star"></i>
//               </li>
//               <li className="reviewstar">
//                 <i class="fas fa-star"></i>
//               </li>
//               <li className="reviewstar">
//                 <i class="fas fa-star"></i>
//               </li>
//               <li className="reviewstar">
//                 <i class="fas fa-star"></i>
//               </li>
//             </ul>
//             <p> {data.description} </p>
//           </div>
//         </div>
//       </>
//     );
//   })}
// </OwlCarousel>;
