import React from 'react';
import Portfolio from '../../../pages/portfolio/Portfolio.js';

const PortfolioPage = () => {
    return (
        <>
            <Portfolio />
        </>
    )
}

export default PortfolioPage;