import React from 'react'
import Perfect from '../../../pages/BlogPage/perfect/Perfect'
import Preparing from '../../../pages/BlogPage/preparing/Preparing'

const BlogPage = () => {
    return (
        <>
            <Preparing />
            <Perfect />
        </>
    )
}

export default BlogPage
