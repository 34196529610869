import React from 'react';
import blogpage2 from "../../../assets/images/blogpage2.png";
import "./Perfect.css";
import "../preparing/Preparing.css";

const Perfect = () => {
    return (
        <>
            <section className="perfect">
                <div className="container mx-auto">
                    <div className="perfect_bike">
                        <p className="porttitor commodo_duis text-center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in eget varius montes, ut in. Massa risus nibh commodo duis.
                        </p>
                        <p className="nulla_sed time_para">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis rhoncus sollicitudin in ac pulvinar. Vel elementum, in mi, tristique
                            ornare nunc sollicitudin. Pharetra vestibulum morbi vulputate non aliquet. Amet imperdiet at in mauris. Dignissim nulla donec tempus
                            sagittis pretium facilisi nisi tortor. Diam malesuada velit, quis malesuada scelerisque aenean in ut. Sit tincidunt vulputate sociis
                            nec, dui. Eu donec venenatis dignissim pulvinar adipiscing sem. Neque, et lacus, augue nullam risus at sit morbi varius. Tincidunt
                            proin vulputate cras vitae gravida interdum habitasse vitae morbi.
                        </p>
                        <img src={blogpage2} alt="img" className="bancking travel" />
                        <p className="porttitor text-[#868E96] main_title_head">
                            What are these techniques ?
                        </p>
                        <ul className="detail">
                            <li className="blog_detail">
                                <p className="tincidunt time_para">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis rhoncus sollicitudin in ac pulvinar. Vel elementum, in mi,
                                    tristique ornare nunc sollicitudin.
                                </p>
                            </li>
                            <li className="blog_detail">
                                <p className="tincidunt time_para">
                                    Pharetra vestibulum morbi vulputate non aliquet.Amet imperdiet at in mauris.
                                </p>
                            </li>
                            <li className="blog_detail">
                                <p className="tincidunt time_para">
                                    Dignissim nulla donec tempus sagittis pretium facilisi nisi tortor. Diam malesuada velit, quis malesuada scelerisque aenean in ut.
                                </p>
                            </li>
                            <li className="blog_detail">
                                <p className="tincidunt time_para">
                                    Sit tincidunt vulputate sociis nec, dui. Eu donec venenatis dignissim pulvinar adipiscing sem. Neque, et lacus, augue nullam risus at sit morbi varius.
                                </p>
                            </li>
                            <li className="blog_detail">
                                <p className="tincidunt time_para">
                                    Tincidunt proin vulputate cras vitae gravida interdum habitasse vitae morbi.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Perfect;

