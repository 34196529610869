import React from 'react';
import "./Contact.css";

const Contact = () => {
    return (
        <>
            <section className="contact" id='contact'>
                <div className="container mx-auto">
                    <div className="contactme">
                        <div className="contactus">
                            <div data-aos="fade-up" data-aos-duration="1000">
                                <h2 className="igiveaoffer main_title_head">Contact</h2>
                            </div>
                        </div>
                        <ul className="address">
                            <li className="phone">
                                <div className="avadh">
                                    <span>
                                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="40" cy="40" r="40" fill="#FEDFD7" />
                                            <path d="M51.4935 35.2668C50.0935 29.1068 44.7201 26.3335 40.0001 26.3335C40.0001 26.3335 40.0001 26.3335 39.9868 26.3335C35.2801 26.3335 29.8935 29.0935 28.4935 35.2535C26.9335 42.1335 31.1468 47.9602 34.9601 51.6268C36.3735 52.9868 38.1868 53.6668 40.0001 53.6668C41.8135 53.6668 43.6268 52.9868 45.0268 51.6268C48.8401 47.9602 53.0535 42.1468 51.4935 35.2668ZM40.0001 41.9468C37.6801 41.9468 35.8001 40.0668 35.8001 37.7468C35.8001 35.4268 37.6801 33.5468 40.0001 33.5468C42.3201 33.5468 44.2001 35.4268 44.2001 37.7468C44.2001 40.0668 42.3201 41.9468 40.0001 41.9468Z" fill="#F75124" />
                                        </svg>
                                    </span>
                                    <div className="dizme">
                                        <p className='card_title_main'>Address</p>
                                        <a className='card_description_main' href="https://www.google.com/maps/place/Del%C3%A9mont+Studio/@21.2312576,72.9015264,17z/data=!3m1!4b1!4m5!3m4!1s0x3be0450ee683cc69:0x421e86496d19aa00!8m2!3d21.2312576!4d72.9037151" target="_blank">Turku, Finland</a>
                                    </div>
                                </div>
                            </li>
                            <li className="phone">
                                <div className="avadh">
                                    <span>
                                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="40" cy="40" r="40" fill="#DDF5E6" />
                                            <path d="M46.667 28.6665H33.3337C29.3337 28.6665 26.667 30.6665 26.667 35.3332V44.6665C26.667 49.3332 29.3337 51.3332 33.3337 51.3332H46.667C50.667 51.3332 53.3337 49.3332 53.3337 44.6665V35.3332C53.3337 30.6665 50.667 28.6665 46.667 28.6665ZM47.2937 36.7865L43.1203 40.1198C42.2403 40.8265 41.1203 41.1732 40.0003 41.1732C38.8803 41.1732 37.747 40.8265 36.8803 40.1198L32.707 36.7865C32.2803 36.4398 32.2137 35.7998 32.547 35.3732C32.8937 34.9465 33.5203 34.8665 33.947 35.2132L38.1203 38.5465C39.1337 39.3598 40.8537 39.3598 41.867 38.5465L46.0403 35.2132C46.467 34.8665 47.107 34.9332 47.4403 35.3732C47.787 35.7998 47.7203 36.4398 47.2937 36.7865Z" fill="#1DBE59" />
                                        </svg>
                                    </span>
                                    <div className="dizme">
                                        <p className='card_title_main'>Email</p>
                                        <a className='card_description_main' href="mailto:Info@punitkathiriya.com">punitkathiriya@gmail.com</a>
                                    </div>
                                </div>
                            </li>
                            <li className="phone">
                                <div className="avadh">
                                    <span>
                                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="40" cy="40" r="40" fill="#ECE8FD" />
                                            <path d="M38.7337 43.9332L36.267 46.3998C35.747 46.9198 34.9203 46.9198 34.387 46.4132C34.2403 46.2665 34.0937 46.1332 33.947 45.9865C32.5737 44.5998 31.3337 43.1465 30.227 41.6265C29.1337 40.1065 28.2537 38.5865 27.6137 37.0798C26.987 35.5598 26.667 34.1065 26.667 32.7198C26.667 31.8132 26.827 30.9465 27.147 30.1465C27.467 29.3332 27.9737 28.5865 28.6803 27.9198C29.5337 27.0798 30.467 26.6665 31.4537 26.6665C31.827 26.6665 32.2003 26.7465 32.5337 26.9065C32.8803 27.0665 33.187 27.3065 33.427 27.6532L36.5203 32.0132C36.7603 32.3465 36.9337 32.6532 37.0537 32.9465C37.1737 33.2265 37.2403 33.5065 37.2403 33.7598C37.2403 34.0798 37.147 34.3998 36.9603 34.7065C36.787 35.0132 36.5337 35.3332 36.2137 35.6532L35.2003 36.7065C35.0537 36.8532 34.987 37.0265 34.987 37.2398C34.987 37.3465 35.0003 37.4398 35.027 37.5465C35.067 37.6532 35.107 37.7332 35.1337 37.8132C35.3737 38.2532 35.787 38.8265 36.3737 39.5198C36.9737 40.2132 37.6137 40.9198 38.307 41.6265C38.4403 41.7598 38.587 41.8932 38.7203 42.0265C39.2537 42.5465 39.267 43.3998 38.7337 43.9332Z" fill="#8067F0" />
                                            <path d="M53.2931 48.4402C53.2931 48.8135 53.2265 49.2002 53.0931 49.5735C53.0531 49.6802 53.0131 49.7869 52.9598 49.8935C52.7331 50.3735 52.4398 50.8269 52.0531 51.2535C51.3998 51.9735 50.6798 52.4936 49.8665 52.8269C49.8531 52.8269 49.8398 52.8402 49.8265 52.8402C49.0398 53.1602 48.1865 53.3335 47.2665 53.3335C45.9065 53.3335 44.4531 53.0135 42.9198 52.3602C41.3865 51.7069 39.8531 50.8269 38.3331 49.7202C37.8131 49.3336 37.2931 48.9469 36.7998 48.5335L41.1598 44.1735C41.5331 44.4535 41.8665 44.6669 42.1465 44.8135C42.2131 44.8402 42.2931 44.8802 42.3865 44.9202C42.4931 44.9602 42.5998 44.9735 42.7198 44.9735C42.9465 44.9735 43.1198 44.8935 43.2665 44.7469L44.2798 43.7469C44.6131 43.4135 44.9331 43.1602 45.2398 43.0002C45.5465 42.8135 45.8531 42.7202 46.1865 42.7202C46.4398 42.7202 46.7065 42.7735 46.9998 42.8935C47.2931 43.0135 47.5998 43.1869 47.9331 43.4135L52.3465 46.5469C52.6931 46.7869 52.9331 47.0669 53.0798 47.4002C53.2131 47.7335 53.2931 48.0669 53.2931 48.4402Z" fill="#8067F0" />
                                        </svg>
                                    </span>
                                    <div className="dizme">
                                        <p className='card_title_main'>Phone</p>
                                        <a className='card_description_main' href="tel:+358415739627">+358 415739627</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
