import React from 'react';
import blogpage1 from "../../../assets/images/blogpage1.png";
import "./Preparing.css";
import "../perfect/Perfect.css";

const Preparing = () => {

    return (
        <>
            <section className="preparing">
                <div className="container mx-auto">
                    <div className="lorempreparing">
                        <span className="preparing_lorem_site text-center">
                            Lorem ipsum dolor sit amet | 2 mins ago
                        </span>
                        <h2>Preparing for UCAT</h2>
                        <span className="preparing_lorem_site jack_zenial text-[#ADB5BD]">by Jack Zenial</span>
                        <img src={blogpage1} alt="img" className="bancking" />
                        <p className="porttitor">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia
                            in eget varius montes, ut in. Massa risus nibh commodo duis. Maecenas porta aliquam tellus iaculis enim eget. Ligula
                            suspendisse <span className='text-[#BF3030]'> vulputate lectus </span> porttitor urna.
                        </p>
                        <p className="nulla_sed time_para">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis rhoncus sollicitudin in ac pulvinar. Vel elementum, in mi, tristique
                            ornare nunc sollicitudin. Pharetra vestibulum morbi vulputate non aliquet. Amet imperdiet at in mauris. Dignissim nulla donec tempus
                            sagittis pretium facilisi nisi tortor. Diam malesuada velit, quis malesuada scelerisque aenean in ut. Sit tincidunt vulputate sociis
                            nec, dui. Eu donec venenatis dignissim pulvinar adipiscing sem. Neque, et lacus, augue nullam risus at sit morbi varius. Tincidunt proin
                            vulputate cras vitae gravida interdum habitasse vitae morbi.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Preparing
