import React, { useEffect, useState } from "react";
import RouterLink from "../../routerlink/RouterLink";
import "./Loader.css"

function Loader() {

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 1000)
    }, [])

    return (
        <>
            {loader
                ?
                (
                    <div className="mainloader h-[100vh] flex justify-center items-center">
                        <div className="justify-center items-center loader">
                        </div>
                    </div>
                )
                :
                (<RouterLink />)
            }
        </>
    );
}

export default Loader;