import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import CountUp from "react-countup";
import mainimg from "../../../assets/images/mainimg.png";
import ScrollDown from "../../../componate/scroll-down/ScrollDown";

const Hero = () => {
  return (
    <>
      <section className="herosec" id="home">
        <div class="container mx-auto">
          <div class="grid grid-cols-2 gap-4">
            <div className="hellopunitbhai" data-aos="fade-up">
              <span className="hello differentcolor">Hello, I’m</span>
              <h1>Punit Kathiriya</h1>
              <p className="whitetext">
                A <span className="differentcolor">professional developer</span>{" "}
                from <span className="surat text-[#F75023]">Finland</span>
              </p>
              <div className="complateproject">
                <Link className="clientsproject" to="/">
                  <span className="twothousand">
                    <CountUp className="count_pro" duration={2} end={2000} />+
                  </span>
                  Completed Projects
                </Link>
                <Link className="clientsproject" to="/">
                  <span className="twothousand">
                    <CountUp duration={2} end={80} />
                  </span>
                  Different country Clients
                </Link>
              </div>
            </div>
            <div className="mainimg">
              <img className="punitmainimage" src={mainimg} alt="img" />
            </div>
          </div>
        </div>
        <ScrollDown />
      </section>
    </>
  );
};

export default Hero;
