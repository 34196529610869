import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Brands.css";
import api from "../../../Punitportfolio";
import Aos from "aos";
import "aos/dist/aos.css";

const Brands = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section className="brandsdata">
        <div className="container mx-auto">
          <div className="dictum">
            <div className="brandsdetail">
              <div data-aos="fade-up" data-aos-duration="1000">
                <span className="dictum_et description_main">Work with</span>
                <h2 className="igiveaoffer main_title_head">Brands</h2>
                <p className="services_feugiat time_para">
                  I was working with this amazing brand and complete their
                  projects.
                </p>
                {/* <div className="see_all_integrations">
                                    <Link to="/" className="viewallbtn">See all integrations
                                        <span className="next">
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.9225 7.41078L12.5 0.988281L11.3217 2.16661L16.3217 7.16661H1.66671C1.20587 7.16661 0.833374 7.53995 0.833374 7.99995C0.833374 8.45995 1.20587 8.83328 1.66671 8.83328H16.3217L11.3217 13.8333L12.5 15.0116L18.9225 8.58911C19.2484 8.26328 19.2484 7.73661 18.9225 7.41078Z" fill="white" />
                                            </svg>
                                        </span>
                                    </Link>
                                </div> */}
              </div>
            </div>
            <ul className="brands_zapier">
              {api.brands.map((data) => {
                return (
                  <>
                    <li
                      className="brands_slack"
                      key={data.id}
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <div className={data.brandsClassName}>
                        <Link to="/">
                          <img
                            className="dark_brands_img"
                            src={data.brandsImages}
                            alt="img"
                          />
                          <img
                            className="light_brands_img"
                            src={data.brandsImageslightimg}
                            alt="img"
                          />
                        </Link>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brands;
