import React from 'react'
import "./ScrollDown.css"

const ScrollDown = () => {
    return (
        <>
            <a href="#demo">
                <div id="wrapperid">
                    <div id="wrapper-inner">
                        <div id="scroll-down">
                            <span class="arrow-down">
                            </span>
                            <span id="scroll-title">
                                Scroll down
                            </span>
                        </div>
                    </div>
                </div>
            </a>
        </>
    )
}

export default ScrollDown
