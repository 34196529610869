import React from "react";
import "./WhatCanIDo.css";
import api from "../../../Punitportfolio";

const WhatCanIDo = () => {
  return (
    <section className="whatcanido" id="demo">
      <div class="container mx-auto">
        <div className="pexel">
          <div className="innerdiv" data-aos="fade-up">
            <span className="whatservices description_main">SERVICES</span>
            <h2 className="whatcanidothat main_title_head">
              What I can do for you
            </h2>
            <p className="time_para">
              I will bring the breathe of our experience and industry knowledge
              to help you succeed
            </p>
          </div>
          <div className="perfectdesign">
            <ul className="bestquality">
              {api.whatdoido.map((data) => {
                return (
                  <>
                    <li
                      data-aos="flip-left"
                      data-aos-delay="300"
                      data-aos-duration="2000"
                      className="communication hover:!blur-none group-hover:blur-sm"
                      key={data.id}
                    >
                      <div className={data.className}>
                        <img src={data.whatdoidoimg} alt="img" />
                        <h6 className="card_title_main">
                          {data.whatdoidohead}
                        </h6>
                        <p className="card_description_main">
                          {data.whatdoidodetail}
                        </p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatCanIDo;
