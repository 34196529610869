import React from 'react';
import AnimatedCursor from 'react-animated-cursor';
import "./Cursor.css";

const Cursor = () => {


    return (
        <>
            <div className="cursor">
                <AnimatedCursor className="customcursor"
                    clickables={[
                        'a',
                        'input[type="text"]',
                        'input[type="email"]',
                        'input[type="number"]',
                        'input[type="submit"]',
                        'input[type="image"]',
                        'label[for]',
                        'select',
                        'textarea',
                        'button',
                        '.link'
                    ]}
                    color="255,255,255"
                    innerSize={8}
                    outerSize={35}
                    innerScale={1}
                    outerScale={1.7}
                    outerAlpha={0}
                    outerStyle={{
                        border: '1px solid #fff'
                    }}
                />
            </div>
        </>
    )
}

export default Cursor
