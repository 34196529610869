import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PortfolioPage from "./RouterPage/PortfolioPage/PortfolioPage.js";
import BlogPage from "./RouterPage/BlogPage/BlogPage.js";
import HomePage from "./RouterPage/HomePage/HomePage.js";
import { Footer, Header } from "../layout/index.js";
import ScrollToTop from "./ScrollToTopRoute.js";

const RouterLink = () => {
  return (
    <>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/blog" element={<BlogPage />} />
          <Route exact path="/portfolio" element={<PortfolioPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
};

export default RouterLink;
