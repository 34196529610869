import React from "react";
import Amazing from "../../../pages/Home/amazing/Amazing.js";
// import Blogs from "../../../pages/Home/blogs/Blogs.js";
import Brands from "../../../pages/Home/brands/Brands.js";
import Clients from "../../../pages/Home/clients/Clients.js";
import Contact from "../../../pages/Home/contact/Contact.js";
import Developer from "../../../pages/Home/developer/Developer.js";
import Experience from "../../../pages/Home/experience/Experience.js";
import Hero from "../../../pages/Home/hero/Hero.js";
import Services from "../../../pages/Home/services/Services.js";
import WhatCanIDo from "../../../pages/Home/whatcanido/WhatCanIDo.js";

const HomePage = () => {
  return (
    <>
      <Hero />
      <WhatCanIDo />
      <Developer />
      <Services />
      <Amazing />
      <Experience />
      <Brands />
      <Clients />
      {/* <Blogs /> */}
      <Contact />
    </>
  );
};

export default HomePage;
