import React from "react";
import developermain from "../../../assets/images/developermain.png";
import "./Developer.css";
import api from "../../../Punitportfolio";

const Developer = () => {
  return (
    <>
      <section className="developer" id="aboutus">
        <div class="container mx-auto">
          <div class="grid grid-cols-2 gap-4">
            <div data-aos="fade-up" className="iamdeveloper">
              <span className="description_main">About Me</span>
              <h2 className="whatcanidothat  main_title_head">
                I am Punit Kathiriya From Finland
              </h2>
              <p className="time_para">
                As a senior software developer with expertise in PHP, I have
                extensive experience in designing, developing, and deploying
                complex web-based applications.
              </p>
              <div className="viewall">
                <a href="#contact" className="viewallbtn">
                  Contact Me{" "}
                </a>
              </div>
            </div>
            <div className="developerpics">
              <div data-aos="fade-up" data-aos-duration="1000">
                <img
                  className="developeemainimg developermainresponciveclass"
                  src={developermain}
                  alt=""
                />
              </div>
              {api.developer.map((data) => {
                return (
                  <>
                    <span className={data.developerspanclass}>
                      <img src={data.developerspanimages} alt="img" />
                    </span>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Developer;
