import './App.css';
import Cursor from './componate/cursor/Cursor';
import React, { useEffect } from 'react';
import AOS from 'aos'
import "aos/dist/aos.css";
import Loader from './componate/loader/Loader';
import ScrollToTop from 'react-scroll-to-top';
import "./assets/css/index"

function App() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div>
        <div>
          <ScrollToTop smooth color="#6f00ff" />
          <Loader />
          <Cursor />
        </div>
      </div>
    </>
  );
}

export default App;

