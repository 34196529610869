import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import api from "../../Punitportfolio";
import Aos from "aos";
import "aos/dist/aos.css";
import Fancybox from "../../componate/fancybox/FancyBox";

const Portfolio = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 992);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth > 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section className="portfolio" id="">
        <div className="container mx-auto">
          <div className="portfoliopagecontact">
            <div
              className="portfolio_work"
              id="portfolioo"
              ata-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <span className="testimonials description_main">Portfoilio</span>
              <h2 className="igiveaoffer main_title_head">My Fabulous work</h2>
              <p className="services_feugiat time_para">
                Specialising in the latest web design offering web consultancy
                and digital solutions to increase leads and sales.
              </p>
            </div>
            <div className="fileds">
              <ul className="alldataimage">
                {api.portfoilo.map((data) => {
                  return (
                    <>
                      <li
                        className={data.className}
                        key={data.id}
                        data-aos="zoom-in"
                      >
                        <div
                          className={`${
                            isMobile ? "alldesign" : "mobile-class"
                          }`}
                        >
                          <Fancybox>
                            <img
                              data-fancybox="gallery"
                              src={data.image}
                              className="mainportfolioimg"
                              alt="img"
                            />
                          </Fancybox>
                          <div class="detailss">
                            <span class="infodata"> {data.title} </span>
                            <span class="title"> {data.name} </span>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
